import React from 'react'
import { useState } from 'react'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'

import { useRef } from 'react'
import {
  AutoHeightContainer,
  ContactBackgroundContainer,
  ContactContentContainer,
  ContactHeadingContainer,
  ContactTextAnchor,
  IntroBackgroundContainer,
  IntroHeadingContainer,
  SoftwareBackgroundContainer,
  SoftwareContentContainer,
  SoftwareHeadingContainer,
  SplashMenu,
  StevenTextAnchor,
  ViewportContainer,
  WorkshopBackgroundContainer,
  WorkshopContainer,
  WorkshopContentContainer,
  WorkshopHeadingContainer,
} from './landing-page.styles'
import { scrollIntoView } from 'utils/handlers'
import { ContactForm } from 'components/contact/contact.component'
import { delayPromise } from 'utils/promise'
import { services } from 'services'
import { Layout } from 'components/layout/layout.component'

export interface PeertjeLabsLandingPageProps {
  sessionToken: string
}

export const PeertjeLabsLandingPage: React.FunctionComponent<PeertjeLabsLandingPageProps> = () => {
  const messageFieldRef = useRef(null)
  const [contactScrollPointRef, setContactScrollPointRef] = useState<
    HTMLDivElement
  >()
  const [sendContactSuccess, setSendContactSuccess] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  return (
    <Layout>
      <Row style={{ height: '100%', padding: 0, margin: 0 }}>
        <Col xs={12} style={{ height: '100%', padding: 0, margin: 0 }}>
          <ViewportContainer>
            <IntroBackgroundContainer></IntroBackgroundContainer>
            <IntroHeadingContainer>
              <div
                style={{
                  position: 'relative',
                  textAlign: 'center',
                  margin: '0 auto',
                  padding: '0 1em',
                }}
              >
                <h1>Peertje Labs</h1>
                <SplashMenu>
                  <div>
                    <StevenTextAnchor href="/steven">Steven</StevenTextAnchor>
                  </div>
                  <div>
                    <ContactTextAnchor
                      onClick={() =>
                        scrollIntoView(contactScrollPointRef, window, () =>
                          (messageFieldRef.current as any).focus(),
                        )
                      }
                    >
                      contact
                    </ContactTextAnchor>
                  </div>
                </SplashMenu>
              </div>
            </IntroHeadingContainer>
          </ViewportContainer>
          <AutoHeightContainer>
            <Grid>
              <Row>
                <Col xs={12}>
                  <ContactHeadingContainer>
                    <h2>Contact</h2>
                  </ContactHeadingContainer>
                </Col>
              </Row>
            </Grid>
            <ContactContentContainer>
              <ContactBackgroundContainer />
              <Row>
                <Col xs={12} md={6} mdOffset={3} lg={4} lgOffset={4}>
                  <ContactForm
                    backupEmail={'steven@peertjelabs.nl'}
                    messageFieldRef={messageFieldRef}
                    success={sendContactSuccess}
                    errorMessage={errorMessage}
                    handleSendMessage={data => {
                      setIsLoading(true)
                      return services.peertjelabs
                        .submitContactForm(data)
                        .then(result => {
                          setSendContactSuccess(true)
                          setIsLoading(false)
                          return delayPromise(5000, result).then(() => {
                            setSendContactSuccess(false)
                            return result
                          })
                        })
                        .catch(() => setIsLoading(false))
                    }}
                    loading={isLoading}
                  />
                </Col>
              </Row>
            </ContactContentContainer>
            <div ref={el => setContactScrollPointRef(el as any)}></div>
          </AutoHeightContainer>
        </Col>
      </Row>
    </Layout>
  )
}

const Software = () => {
  const [softwareScrollPointRef, setSoftwareScrollPointRef] = useState<
    HTMLDivElement
  >()

  return (
    <AutoHeightContainer>
      <div ref={el => setSoftwareScrollPointRef(el as any)}></div>
      <Grid>
        <Row>
          <Col xs={12}>
            <SoftwareHeadingContainer>
              <h1>Software</h1>
            </SoftwareHeadingContainer>
          </Col>
        </Row>
      </Grid>
      <SoftwareContentContainer>
        <SoftwareBackgroundContainer></SoftwareBackgroundContainer>
      </SoftwareContentContainer>
    </AutoHeightContainer>
  )
}

const Workshop = () => {
  const [workshopScrollPointRef, setWorkshopScrollPointRef] = useState<
    HTMLDivElement
  >()
  return (
    <AutoHeightContainer>
      <WorkshopContainer>
        <div ref={el => setWorkshopScrollPointRef(el as any)}></div>
        <Grid>
          <Row>
            <Col xs={12}>
              <WorkshopHeadingContainer>
                <h1>Workshop</h1>
              </WorkshopHeadingContainer>
            </Col>
          </Row>
        </Grid>
        <WorkshopContentContainer>
          <WorkshopBackgroundContainer></WorkshopBackgroundContainer>
        </WorkshopContentContainer>
      </WorkshopContainer>
    </AutoHeightContainer>
  )
}
