import styled from 'styled-components'

export const ViewportContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: relative;
`

export const AutoHeightContainer = styled.div`
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: relative;
`

export const IntroHeadingContainer = styled.div`
  text-align: center;
  transform: translateY(-50%);
  position: absolute;
  letter-spacing: 2px;
  top: 50%;
  font-size: 2em;
  color: white;
  width: 100%;
`

export const IntroBackgroundContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.7;
  z-index: -1;
`

export const SoftwareBackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.7;
  z-index: -1;
`

export const SoftwareContentContainer = styled.div`
  position: relative;
  padding: 0 0 4em 0;
`

export const SoftwareHeadingContainer = styled.div`
  color: #333;
  padding-top: 1em;
  padding-bottom: 0.6em;
  font-size: 2em;
  background-color: #fff;
  text-align: center;
`

export const WorkshopBackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.7;
  z-index: -1;
`

export const WorkshopContainer = styled(AutoHeightContainer)`
  background: #e1e1e1;
`

export const WorkshopContentContainer = styled.div`
  position: relative;
  background: #e1e1e1;
  padding: 0 0 4em 0;
`

export const WorkshopHeadingContainer = styled.div`
  color: #333;
  background: #e1e1e1;
  padding-top: 1em;
  padding-bottom: 0.6em;
  font-size: 2em;
  text-align: center;
`

export const ContactBackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.7;
  z-index: -1;
`

export const ContactContentContainer = styled.div`
  position: relative;
  padding: 0 2em 2em 2em;
`

export const ContactHeadingContainer = styled.div`
  color: #333;
  padding-top: 1em;
  padding-bottom: 0.6em;
  font-size: 2em;
  background-color: #fff;
  text-align: center;
`

export const SplashMenu = styled.div`
  text-transform: uppercase;
  font-size: 0.6em;
  line-height: 1.4em;
`

export const StevenTextAnchor = styled.a`
  color: #333;
  color: white;
  text-decoration: none;
  :hover {
    color: #333;
  }
`

export const ContactTextAnchor = styled.a`
  cursor: pointer;
  color: #333;
  color: white;
  text-decoration: none;
  :hover {
    color: #333;
  }
`
